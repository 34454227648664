/*
	Stylesheet stromu kategorii e-shopu TYMA CZ
*/
.nav-tree li {
  zoom: 1;
}
.nav-tree li:before, .nav-tree li:after {
  content: "";
  display: table;
}
.nav-tree li:after {
  clear: both;
}

.nav-tree li:before {
  display: none;
}

.eshop-submenu-box h2 {
  background: #007bc4;
  color: #fff;
}
.eshop-submenu-box h2 a {
  color: #fff;
}

/*
    e-shop navigace produkty
 */
.eshop-submenu-box h2 {
  display: none;
  margin: 0;
  font-size: 1rem;
}
.eshop-submenu-box h2 a {
  display: block;
  padding: 20px;
  text-decoration: none;
}

.nav-tree {
  padding: 20px;
  border: 1px solid #efefef;
  border-top: 0;
  border-bottom: 0;
}
.nav-tree ul {
  margin: 0;
  padding-left: 0;
}
.nav-tree li {
  margin: 0;
  padding-left: 0;
}
.nav-tree li:not(:last-child) {
  padding-bottom: 18px;
}
.nav-tree li a {
  display: block;
  margin-left: 32px;
  font-weight: 700;
  text-decoration: none;
}
.nav-tree li span:not(.tree-item-specification) {
  float: left;
  width: 20px;
  height: 20px;
  content: "";
  background: var(--url-open-ab) no-repeat;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .nav-tree li span:not(.tree-item-specification) {
    background: url(../../gfx/open-ab.svg) no-repeat;
  }
}
.nav-tree li ul {
  display: none;
  margin: 18px 0 18px 32px;
}
.nav-tree--ajax {
  min-height: 500px;
}

.tree-subset-heading {
  display: none;
  margin: 1rem 0 0 32px;
  font-size: 12px;
  color: #1d4259;
  text-transform: uppercase;
}
.is-open li.is-open .tree-subset-heading {
  display: block;
}

.nav-tree li.is-open:not(.no-child) {
  padding-bottom: 0;
}
.nav-tree li.is-open:not(.no-child) > ul {
  display: block;
}
.nav-tree li.is-open:not(.no-child) > span:not(.tree-item-specification) {
  background: var(--url-close-b) no-repeat;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .nav-tree li.is-open:not(.no-child) > span:not(.tree-item-specification) {
    background: url(../../gfx/close-b.svg) no-repeat;
  }
}

.nav-tree li.no-child > span:not(.tree-item-specification) {
  background: var(--url-square-ab) no-repeat;
  cursor: default;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .nav-tree li.no-child > span:not(.tree-item-specification) {
    background: url(../../gfx/square-ab.svg) no-repeat;
  }
}

.nav-tree .current-page > a {
  font-weight: 700;
  color: #007bc4;
}

.nav-tree ul li:not(:last-child):before {
  display: block;
  position: absolute;
  top: 25px;
  left: 0;
  height: calc(100% - 30px);
  width: 10.5px;
  border-right: 1px dotted black;
  background: transparent;
  content: "";
}

.nav-tree li.sale:not(.is-open) span:not(.tree-item-specification) {
  background: url(../../gfx/square-gr.svg) left center no-repeat;
}
.nav-tree li.sale:not(.is-open) a {
  color: #adadad;
}

.tree-item-specification {
  font-size: 0.857rem;
  font-weight: 400;
  color: #adadad;
}

/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 900px) {
  .eshop-submenu-box h2 {
    display: block;
  }
}

/*# sourceMappingURL=nav-tree.css.map */